export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';


export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';

// 1
export const ADD_NEWS = 'ADD_NEWS';
export const GET_NEWS = 'GET_NEWS';
export const NEWS_ERROR = 'NEWS_ERROR';
export const DELETE_NEWS = 'DELETE_NEWS';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const CHANGE_STATUS_NEWS = 'CHANGE_STATUS_NEWS';
export const SINGLE_NEWS = 'SINGLE_NEWS';
export const SEARCH_NEWS = 'SEARCH_NEWS';

// 2
export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const LANGUAGE_ERROR = 'LANGUAGE_ERROR';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const CHANGE_STATUS_LANGUAGE = 'CHANGE_STATUS_LANGUAGE';
export const GET_LANGUAGE = 'GET_LANGUAGE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

// 3
export const CREATE_STATE = 'CREATE_STATE';
export const GET_STATES = 'GET_STATES';
export const STATE_ERROR = 'STATE_ERROR';
export const DELETE_STATE = 'DELETE_STATE';
export const CHANGE_STATUS_STATE = 'CHANGE_STATUS_STATE';
export const GET_STATE = 'GET_STATE';
export const UPDATE_STATE = 'UPDATE_STATE';

// 4
export const CREATE_DISTRICT = 'CREATE_DISTRICT';
export const GET_DISTRICTS = 'GET_DISTRICTS';
export const DISTRICT_ERROR = 'DISTRICT_ERROR';
export const DELETE_DISTRICT = 'DELETE_DISTRICT';
export const CHANGE_STATUS_DISTRICT = 'CHANGE_STATUS_DISTRICT';
export const GET_DISTRICT = 'GET_DISTRICT';
export const UPDATE_DISTRICT = 'UPDATE_DISTRICT';

// 5
export const CREATE_AREA = 'CREATE_AREA';
export const GET_AREAS = 'GET_AREAS';
export const AREA_ERROR = 'AREA_ERROR';
export const DELETE_AREA = 'DELETE_AREA';
export const CHANGE_STATUS_AREA = 'CHANGE_STATUS_AREA';
export const GET_AREA = 'GET_AREA';
export const UPDATE_AREA = 'UPDATE_AREA';

// 6
export const CREATE_PINCODE = 'CREATE_PINCODE';
export const GET_PINCODES = 'GET_PINCODES';
export const PINCODE_ERROR = 'PINCODE_ERROR';
export const DELETE_PINCODE = 'DELETE_PINCODE';
export const CHANGE_STATUS_PINCODE = 'CHANGE_STATUS_PINCODE';
export const GET_PINCODE = 'GET_PINCODE';
export const UPDATE_PINCODE = 'UPDATE_PINCODE';

// 7
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const CHANGE_STATUS_CATEGORY = 'CHANGE_STATUS_CATEGORY';
export const GET_CATEGORY = 'GET_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';

// 8
export const CREATE_FOOTER_LOGO = 'CREATE_FOOTER_LOGO';
export const GET_FOOTER_LOGOS = 'GET_FOOTER_LOGOS';
export const FOOTER_LOGO_ERROR = 'FOOTER_LOGO_ERROR';
export const DELETE_FOOTER_LOGO = 'DELETE_FOOTER_LOGO';
export const CHANGE_STATUS_FOOTER_LOGO = 'CHANGE_STATUS_FOOTER_LOGO';
export const GET_FOOTER_LOGO = 'GET_FOOTER_LOGO';
export const UPDATE_FOOTER_LOGO = 'UPDATE_FOOTER_LOGO';

// 9
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const CHANGE_STATUS_NOTIFICATION = 'CHANGE_STATUS_NOTIFICATION';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';


// 10
export const CREATE_YOUTUBE_VIDEO = 'CREATE_YOUTUBE_VIDEO';
export const GET_YOUTUBE_VIDEOS = 'GET_YOUTUBE_VIDEOS';
export const YOUTUBE_VIDEO_ERROR = 'YOUTUBE_VIDEO_ERROR';
export const DELETE_YOUTUBE_VIDEO = 'DELETE_YOUTUBE_VIDEO';
export const CHANGE_STATUS_YOUTUBE_VIDEO = 'CHANGE_STATUS_YOUTUBE_VIDEO';
export const GET_YOUTUBE_VIDEO = 'GET_YOUTUBE_VIDEO';
export const UPDATE_YOUTUBE_VIDEO = 'UPDATE_YOUTUBE_VIDEO';


// 11
export const CREATE_HP_SIZE = 'CREATE_HP_SIZE';
export const GET_HP_SIZES = 'GET_HP_SIZES';
export const HP_SIZE_ERROR = 'HP_SIZE_ERROR';
export const DELETE_HP_SIZE = 'DELETE_HP_SIZE';
export const CHANGE_STATUS_HP_SIZE = 'CHANGE_STATUS_HP_SIZE';
export const GET_HP_SIZE = 'GET_HP_SIZE';
export const UPDATE_HP_SIZE = 'UPDATE_HP_SIZE';

// 12
export const CREATE_CULTURE_TYPE = 'CREATE_CULTURE_TYPE';
export const GET_CULTURE_TYPES = 'GET_CULTURE_TYPES';
export const CULTURE_TYPE_ERROR = 'CULTURE_TYPE_ERROR';
export const DELETE_CULTURE_TYPE = 'DELETE_CULTURE_TYPE';
export const CHANGE_STATUS_CULTURE_TYPE = 'CHANGE_STATUS_CULTURE_TYPE';
export const GET_CULTURE_TYPE = 'GET_CULTURE_TYPE';
export const UPDATE_CULTURE_TYPE = 'UPDATE_CULTURE_TYPE';

// 13
export const CREATE_USER_ROLE = 'CREATE_USER_ROLE';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const USER_ROLE_ERROR = 'USER_ROLE_ERROR';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const CHANGE_STATUS_USER_ROLE = 'CHANGE_STATUS_USER_ROLE';
export const GET_USER_ROLE = 'GET_USER_ROLE';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';

// 14
export const HATECHERIES_PDF_ERROR = 'HATECHERIES_PDF_ERROR';
export const GET_HATECHERIES_PDFS = 'GET_HATECHERIES_PDFS';
export const UPDATE_HATECHERIES_PDF = 'UPDATE_HATECHERIES_PDF';

// 15
export const CREATE_USER = 'CREATE_USER';
export const GET_USERS = 'GET_USERS';
export const USER_ERROR = 'USER_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_STATUS_USER = 'CHANGE_STATUS_USER';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';


// 16
export const CREATE_JOB = 'CREATE_JOB';
export const GET_JOBS = 'GET_JOBS';
export const JOB_ERROR = 'JOB_ERROR';
export const DELETE_JOB = 'DELETE_JOB';
export const CHANGE_STATUS_JOB = 'CHANGE_STATUS_JOB';
export const GET_JOB = 'GET_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';


// 17
export const CREATE_JOB_APPLICATION = 'CREATE_JOB_APPLICATION';
export const GET_JOB_APPLICATIONS = 'GET_JOB_APPLICATIONS';
export const JOB_APPLICATION_ERROR = 'JOB_APPLICATION_ERROR';
export const DELETE_JOB_APPLICATION = 'DELETE_JOB_APPLICATION';
export const CHANGE_STATUS_JOB_APPLICATION = 'CHANGE_STATUS_JOB_APPLICATION';
export const GET_JOB_APPLICATION = 'GET_JOB_APPLICATION';
export const UPDATE_JOB_APPLICATION = 'UPDATE_JOB_APPLICATION';


// 18
export const CREATE_SLIDER_IMAGE = 'CREATE_SLIDER_IMAGE';
export const GET_SLIDER_IMAGES = 'GET_SLIDER_IMAGES';
export const SLIDER_IMAGE_ERROR = 'SLIDER_IMAGE_ERROR';
export const DELETE_SLIDER_IMAGE = 'DELETE_SLIDER_IMAGE';
export const CHANGE_STATUS_SLIDER_IMAGE = 'CHANGE_STATUS_SLIDER_IMAGE';
export const GET_SLIDER_IMAGE = 'GET_SLIDER_IMAGE';
export const UPDATE_SLIDER_IMAGE = 'UPDATE_SLIDER_IMAGE';



// 19
export const CREATE_BANNER_IMAGE = 'CREATE_BANNER_IMAGE';
export const GET_BANNER_IMAGES = 'GET_BANNER_IMAGES';
export const BANNER_IMAGE_ERROR = 'BANNER_IMAGE_ERROR';
export const DELETE_BANNER_IMAGE = 'DELETE_BANNER_IMAGE';
export const CHANGE_STATUS_BANNER_IMAGE = 'CHANGE_STATUS_BANNER_IMAGE';
export const GET_BANNER_IMAGE = 'GET_BANNER_IMAGE';
export const UPDATE_BANNER_IMAGE = 'UPDATE_BANNER_IMAGE';


// 20
export const CREATE_APP_BANNER_IMAGE = 'CREATE_APP_BANNER_IMAGE';
export const GET_APP_BANNER_IMAGES = 'GET_APP_BANNER_IMAGES';
export const APP_BANNER_IMAGE_ERROR = 'APP_BANNER_IMAGE_ERROR';
export const DELETE_APP_BANNER_IMAGE = 'DELETE_APP_BANNER_IMAGE';
export const CHANGE_STATUS_APP_BANNER_IMAGE = 'CHANGE_STATUS_APP_BANNER_IMAGE';
export const GET_APP_BANNER_IMAGE = 'GET_APP_BANNER_IMAGE';
export const UPDATE_APP_BANNER_IMAGE = 'UPDATE_APP_BANNER_IMAGE';


// 21
export const CREATE_BEST_DEAL = 'CREATE_BEST_DEAL';
export const GET_BEST_DEALS = 'GET_BEST_DEALS';
export const BEST_DEAL_ERROR = 'BEST_DEAL_ERROR';
export const DELETE_BEST_DEAL = 'DELETE_BEST_DEAL';
export const CHANGE_STATUS_BEST_DEAL = 'CHANGE_STATUS_BEST_DEAL';
export const GET_BEST_DEAL = 'GET_BEST_DEAL';
export const UPDATE_BEST_DEAL = 'UPDATE_BEST_DEAL';


// 22
export const CREATE_APP_SLIDER_IMAGE = 'CREATE_APP_SLIDER_IMAGE';
export const GET_APP_SLIDER_IMAGES = 'GET_APP_SLIDER_IMAGES';
export const APP_SLIDER_IMAGE_ERROR = 'APP_SLIDER_IMAGE_ERROR';
export const DELETE_APP_SLIDER_IMAGE = 'DELETE_APP_SLIDER_IMAGE';
export const CHANGE_STATUS_APP_SLIDER_IMAGE = 'CHANGE_STATUS_APP_SLIDER_IMAGE';
export const GET_APP_SLIDER_IMAGE = 'GET_APP_SLIDER_IMAGE';
export const UPDATE_APP_SLIDER_IMAGE = 'UPDATE_APP_SLIDER_IMAGE';


// 23
export const CREATE_APP_CLASSIFIED_IMAGE = 'CREATE_APP_CLASSIFIED_IMAGE';
export const GET_APP_CLASSIFIED_IMAGES = 'GET_APP_CLASSIFIED_IMAGES';
export const APP_CLASSIFIED_IMAGE_ERROR = 'APP_CLASSIFIED_IMAGE_ERROR';
export const DELETE_APP_CLASSIFIED_IMAGE = 'DELETE_APP_CLASSIFIED_IMAGE';
export const CHANGE_STATUS_APP_CLASSIFIED_IMAGE = 'CHANGE_STATUS_APP_CLASSIFIED_IMAGE';
export const GET_APP_CLASSIFIED_IMAGE = 'GET_APP_CLASSIFIED_IMAGE';
export const UPDATE_APP_CLASSIFIED_IMAGE = 'UPDATE_APP_CLASSIFIED_IMAGE';



// 24
export const CREATE_ADVERTISEMENT = 'CREATE_ADVERTISEMENT';
export const GET_ADVERTISEMENTS = 'GET_ADVERTISEMENTS';
export const ADVERTISEMENT_ERROR = 'ADVERTISEMENT_ERROR';
export const DELETE_ADVERTISEMENT = 'DELETE_ADVERTISEMENT';
export const CHANGE_STATUS_ADVERTISEMENT = 'CHANGE_STATUS_ADVERTISEMENT';
export const GET_ADVERTISEMENT = 'GET_ADVERTISEMENT';
export const UPDATE_ADVERTISEMENT = 'UPDATE_ADVERTISEMENT';


// 25
export const CREATE_SPONSORED_AD = 'CREATE_SPONSORED_AD';
export const GET_SPONSORED_ADS = 'GET_SPONSORED_ADS';
export const SPONSORED_AD_ERROR = 'SPONSORED_AD_ERROR';
export const DELETE_SPONSORED_AD = 'DELETE_SPONSORED_AD';
export const CHANGE_STATUS_SPONSORED_AD = 'CHANGE_STATUS_SPONSORED_AD';
export const GET_SPONSORED_AD = 'GET_SPONSORED_AD';
export const UPDATE_SPONSORED_AD = 'UPDATE_SPONSORED_AD';


// 26
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const GET_COMPANIES = 'GET_COMPANIES';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const CHANGE_STATUS_COMPANY = 'CHANGE_STATUS_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';



// 27
export const CREATE_SALT_PERCENTAGE = 'CREATE_SALT_PERCENTAGE';
export const GET_SALT_PERCENTAGES = 'GET_SALT_PERCENTAGES';
export const SALT_PERCENTAGE_ERROR = 'SALT_PERCENTAGE_ERROR';
export const DELETE_SALT_PERCENTAGE = 'DELETE_SALT_PERCENTAGE';
export const CHANGE_STATUS_SALT_PERCENTAGE = 'CHANGE_STATUS_SALT_PERCENTAGE';
export const GET_SALT_PERCENTAGE = 'GET_SALT_PERCENTAGE';
export const UPDATE_SALT_PERCENTAGE = 'UPDATE_SALT_PERCENTAGE';


// 28
export const CREATE_PL_STAGE = 'CREATE_PL_STAGE';
export const GET_PL_STAGES = 'GET_PL_STAGES';
export const PL_STAGE_ERROR = 'PL_STAGE_ERROR';
export const DELETE_PL_STAGE = 'DELETE_PL_STAGE';
export const CHANGE_STATUS_PL_STAGE = 'CHANGE_STATUS_PL_STAGE';
export const GET_PL_STAGE = 'GET_PL_STAGE';
export const UPDATE_PL_STAGE = 'UPDATE_PL_STAGE';


// 29
export const CREATE_FEED_TYPE = 'CREATE_FEED_TYPE';
export const GET_FEED_TYPES = 'GET_FEED_TYPES';
export const FEED_TYPE_ERROR = 'FEED_TYPE_ERROR';
export const DELETE_FEED_TYPE = 'DELETE_FEED_TYPE';
export const CHANGE_STATUS_FEED_TYPE = 'CHANGE_STATUS_FEED_TYPE';
export const GET_FEED_TYPE = 'GET_FEED_TYPE';
export const UPDATE_FEED_TYPE = 'UPDATE_FEED_TYPE';

// 30
export const CREATE_PEDDLER_TYPE = 'CREATE_PEDDLER_TYPE';
export const GET_PEDDLER_TYPES = 'GET_PEDDLER_TYPES';
export const PEDDLER_TYPE_ERROR = 'PEDDLER_TYPE_ERROR';
export const DELETE_PEDDLER_TYPE = 'DELETE_PEDDLER_TYPE';
export const CHANGE_STATUS_PEDDLER_TYPE = 'CHANGE_STATUS_PEDDLER_TYPE';
export const GET_PEDDLER_TYPE = 'GET_PEDDLER_TYPE';
export const UPDATE_PEDDLER_TYPE = 'UPDATE_PEDDLER_TYPE';


// 31
export const CREATE_CHEMICAL_CATEGORY = 'CREATE_CHEMICAL_CATEGORY';
export const GET_CHEMICAL_CATEGORIES = 'GET_CHEMICAL_CATEGORIES';
export const CHEMICAL_CATEGORY_ERROR = 'CHEMICAL_CATEGORY_ERROR';
export const DELETE_CHEMICAL_CATEGORY = 'DELETE_CHEMICAL_CATEGORY';
export const CHANGE_STATUS_CHEMICAL_CATEGORY = 'CHANGE_STATUS_CHEMICAL_CATEGORY';
export const GET_CHEMICAL_CATEGORY = 'GET_CHEMICAL_CATEGORY';
export const UPDATE_CHEMICAL_CATEGORY = 'UPDATE_CHEMICAL_CATEGORY';


// 32
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const CHANGE_STATUS_PRODUCT = 'CHANGE_STATUS_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';



// 33
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const ORDER_ERROR = 'ORDER_ERROR';
export const DELETE_ORDER = 'DELETE_ORDER';
export const CHANGE_STATUS_ORDER = 'CHANGE_STATUS_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';



// 34
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const GET_PRODUCT_REQUESTS = 'GET_PRODUCT_REQUESTS';
export const PRODUCT_REQUEST_ERROR = 'PRODUCT_REQUEST_ERROR';
export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const CHANGE_STATUS_PRODUCT_REQUEST = 'CHANGE_STATUS_PRODUCT_REQUEST';
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';




// 34
export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const GET_COMPANY_REQUESTS = 'GET_COMPANY_REQUESTS';
export const COMPANY_REQUEST_ERROR = 'COMPANY_REQUEST_ERROR';
export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const CHANGE_STATUS_COMPANY_REQUEST = 'CHANGE_STATUS_COMPANY_REQUEST';
export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';




// 34
export const CREATE_LAB_REQUEST = 'CREATE_LAB_REQUEST';
export const GET_LAB_REQUESTS = 'GET_LAB_REQUESTS';
export const LAB_REQUEST_ERROR = 'LAB_REQUEST_ERROR';
export const DELETE_LAB_REQUEST = 'DELETE_LAB_REQUEST';
export const CHANGE_STATUS_LAB_REQUEST = 'CHANGE_STATUS_LAB_REQUEST';
export const GET_LAB_REQUEST = 'GET_LAB_REQUEST';
export const UPDATE_LAB_REQUEST = 'UPDATE_LAB_REQUEST';




// 34
export const CREATE_TECHNICIAN_REQUEST = 'CREATE_TECHNICIAN_REQUEST';
export const GET_TECHNICIAN_REQUESTS = 'GET_TECHNICIAN_REQUESTS';
export const TECHNICIAN_REQUEST_ERROR = 'TECHNICIAN_REQUEST_ERROR';
export const DELETE_TECHNICIAN_REQUEST = 'DELETE_TECHNICIAN_REQUEST';
export const CHANGE_STATUS_TECHNICIAN_REQUEST = 'CHANGE_STATUS_TECHNICIAN_REQUEST';
export const GET_TECHNICIAN_REQUEST = 'GET_TECHNICIAN_REQUEST';
export const UPDATE_TECHNICIAN_REQUEST = 'UPDATE_TECHNICIAN_REQUEST';

// 35
export const CREATE_COUNT = 'CREATE_COUNT';
export const GET_COUNTS = 'GET_COUNTS';
export const COUNT_ERROR = 'COUNT_ERROR';
export const DELETE_COUNT = 'DELETE_COUNT';
export const CHANGE_STATUS_COUNT = 'CHANGE_STATUS_COUNT';
export const GET_COUNT = 'GET_COUNT';
export const UPDATE_COUNT = 'UPDATE_COUNT';



// 36
export const CREATE_COUNT_TYPE = 'CREATE_COUNT_TYPE';
export const GET_COUNT_TYPES = 'GET_COUNT_TYPES';
export const COUNT_TYPE_ERROR = 'COUNT_TYPE_ERROR';
export const DELETE_COUNT_TYPE = 'DELETE_COUNT_TYPE';
export const CHANGE_STATUS_COUNT_TYPE = 'CHANGE_STATUS_COUNT_TYPE';
export const GET_COUNT_TYPE = 'GET_COUNT_TYPE';
export const UPDATE_COUNT_TYPE = 'UPDATE_COUNT_TYPE';



// 36
export const CREATE_COUNT_AREA = 'CREATE_COUNT_AREA';
export const GET_COUNT_AREAS = 'GET_COUNT_AREAS';
export const COUNT_AREA_ERROR = 'COUNT_AREA_ERROR';
export const DELETE_COUNT_AREA = 'DELETE_COUNT_AREA';
export const CHANGE_STATUS_COUNT_AREA = 'CHANGE_STATUS_COUNT_AREA';
export const GET_COUNT_AREA = 'GET_COUNT_AREA';
export const UPDATE_COUNT_AREA = 'UPDATE_COUNT_AREA';